import React from "react"
import SidebarImages from "./SidebarImages"
import SidebarLinks from "./SidebarLinks"

export default function Sidebar(props) {
  const data = props.data

  return (
    <div>
      <SidebarLinks />
      <SidebarImages data={data} />
    </div>
  )
}
