import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import * as styles from "../styles/firm-philosophy.module.css"

export default function SidebarImages(props) {
  const data = props.data

  return (
    <div>
      {data.allFile.edges.map(image => (
        <GatsbyImage
          image={image.node.childImageSharp.gatsbyImageData}
          key={image.node.id}
          className={styles.sidebarImages}
          alt={
            "Charleston, SC " +
            getSrc(image.node)
              .replace(/.jpg/, "")
              .replace(/.png/, "")
              .match(/([^/]*)$/)[0]
          }
        />
      ))}
    </div>
  )
}
