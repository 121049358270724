import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/firm-philosophy.module.css"
import Sidebar from "../components/Sidebar"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function About({ data }) {
  return (
    <Layout>
      <GatsbySeo
        title="About | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Tecklenburg, Jenkins & Jacoby, LLC provides a variety of legal services to
            businesses and individuals throughout South Carolina, the Southeast,
            and beyond."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/about/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <Sidebar data={data} />
        </section>
        <section className={styles.content}>
          <h1>Dedicated To Your Success</h1>
          <p>
            Tecklenburg, Jenkins & Jacoby, LLC provides a variety of legal
            services to businesses and individuals throughout South Carolina,
            the Southeast, and beyond. We work tirelessly to fulfill our
            clients’ needs. Our attorneys provide the dedicated and
            knowledgeable service that our clients expect and deserve.
          </p>
          <p>
            Our practice was established by{" "}
            <Link to="/attorneys/paul-f-tecklenburg">Paul F. Tecklenburg</Link>{" "}
            , a South Carolina attorney practicing law since 1988. Our areas of
            practice include general civil law and representing companies or
            individuals who have been sued or have legitimate claims. We also
            provide general business representation with an emphasis in{" "}
            <Link to="/practice-areas/south-carolina-transportation-law">
              transportation
            </Link>{" "}
            and maritime law, or{" "}
            <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
              admiralty
            </Link>
            . Additional practice areas include{" "}
            <Link to="/practice-areas/south-carolina-real-estate-law">
              real estate law
            </Link>
            ,{" "}
            <Link to="/practice-areas/south-carolina-business-law">
              business organizations
            </Link>
            , collections, railroad defense,{" "}
            <Link to="/practice-areas/south-carolina-construction-law">
              construction
            </Link>
            ,{" "}
            <Link to="/practice-areas/south-carolina-environmental-law">
              environmental claims
            </Link>
            , and{" "}
            <Link to="/practice-areas/south-carolina-litigation">
              areas of commercial and civil law
            </Link>
          </p>
          <h2>We Know the Law and We Understand Business</h2>
          <p>
            Our experience in commercial litigation, together with our
            understanding of practical business needs, will advance your
            business goals while best protecting you and your business from
            potential legal problems. We provide dedicated, personal attention
            to all of our clients. Our goal at Tecklenburg, Jenkins & Jacoby is
            to make sure that you stay informed about the status of your legal
            matters and the available options.
          </p>
          <h2>Choose an Experienced Maritime Attorney in South Carolina</h2>
          <p>
            Tecklenburg, Jenkins & Jacoby, LLC is a leading South Carolina{" "}
            <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
              maritime law
            </Link>{" "}
            firm, distinguished by its experience in the defense of marine
            claims and maritime disputes. Our firm has an exceptional reputation
            in both South Carolina and the national shipping community and is
            recognized for its innovative solutions to complex maritime issues.
            The <Link to="/attorneys">maritime attorneys</Link> at our firm
            regularly represent vessel owners, insurers, agents, charterers,
            stevedores, freight forwarders, dredging, energy and various
            transportation companies in solving a variety of maritime law
            issues. We also frequently counsel and direct our clients on
            legislation that impacts the maritime industry.
          </p>
          <p>
            We handle legal matters throughout the U.S. from our offices in
            Charleston, South Carolina. Our attorneys frequently appear in state
            and federal courts for litigation involving collisions and other
            marine accidents, pollution liability, cargo, marine insurance,
            personal injuries (including Jones Act), charter parties, yacht
            sales and warranty issues. We have been extensively involved in
            providing admiralty guidance and advice to ship owners, major
            overseas trading companies, owners and transporters of oil and
            hazardous cargo, and insurers.
          </p>
          <p>
            Our law firm also has extensive experience representing land
            transportation companies such as railroads, freight haulers and
            other common carriers. For more information please visit our
            <Link to="/practice-areas/south-carolina-transportation-law">
              {" "}
              transportation
            </Link>{" "}
            and{" "}
            <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
              admiralty
            </Link>{" "}
            pages or <Link to="/contact">contact us</Link>.
          </p>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AboutSidebarImages {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "about" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
